import React, { Component } from 'react';
import './LogoFeed.scss';
import { Image } from '../../Image';
import Carousel from '../../Carousel';
import GatsbyLink from '../../GatsbyLink';
import OnVisible from 'react-on-visible';

class ThisSlider extends Component {
  render () {
    const { gallery, index } = this.props;
    const settings = {
      container:`logo-carousel-inner-${index}`,
      loop: false,
      nav: false,
      controls:false,
      mouseDrag: true,
      gutter: 30,
      items: 6,
      lazyload: true,
      responsive: {
        1600: {
          items: 6,
        },
        1024: {
          items: 5,
        },
        640: {
          items: 4,
        },
        360: {
          items: 2,
        }
      },
      speed: 600,
      autoplay: true
    };
    return (
      <div className="logo-carousel">
        <Carousel settings={settings}>
          {gallery && gallery.map((slide, slide_count) => slide.source_url &&

              <div key={slide_count} className="slide">
                <div className="aspect">
                  <div className="logo">
                    {slide.source_url && <Image src={slide} position="relative" lazy={false}/>}
                  </div>
                </div>
              </div>
          )}
        </Carousel>
      </div>
    )
  }
}

export const LogoFeed = (props) => {
  const { LogoGallery: gallery, title, index } = props;
  return (
    <section className="logo-feed">
      <div className="title"><h3 className="section-title">{title}</h3></div>
      <div className="inner">
        <ThisSlider gallery={gallery} index={index} />
      </div>
    </section>
  )
}
