import React from 'react';
import loadable from '@loadable/component'

export const PostFeed = (props) => {
  if (props.contentType === 'project') {
    const RelatedProjects = loadable(props => import(`../../Related/RelatedProjects.js`))
    return <RelatedProjects {...props} />
  }
  if (props.contentType === 'post') {
    const RelatedPosts = loadable(props => import(`../../Related/RelatedPosts.js`))
    return <RelatedPosts {...props} />
  }
  if (props.contentType === 'instagram') {
    const Instagram = loadable(props => import(`../../Instagram`))
    return <Instagram {...props} />
  }
  return null
}
